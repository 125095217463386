<template>
  <div class="home">
    <head-nav></head-nav>

    <div class="banner-img">
      <swiper
        class="swiper"
        :options="bannerSwiperOption"
      >
        <swiper-slide
          v-for="(item, index) in banners"
          :key="index"
        >
          <img
            :src="item.image.publicUrl"
            :alt="item.title"
          />
        </swiper-slide>

        <div
          class="swiper-pagination banner--swiper-pagination"
          slot="pagination"
        ></div>

      </swiper>

      <div class="swiper-button-prev banner-swiper-button-prev"></div>
      <div class="swiper-button-next banner-swiper-button-next"></div>

    </div>
    <div class="about-box">
      <div class="wrap">
        <div class="introduce">
          <h3 class="introduce-title">
            <img
              :src="$t('about.title-1')"
              alt=""
            />
          </h3>
          <div
            class="introduce-content"
            v-html="$t('home.content')"
          >
          </div>
          <!-- <div class="introduce-img">
            <div
              class="introduce-imgs"
              v-for="(item, index) in $t('about.aboutpage.list-1')"
              :key="index"
            >
              <img
                :src="item.img"
                :alt="item.id"
              />
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="home-list wrap">
      <div class="list-left">
        <div class="left-item">
          <div>
            <img
              v-for="(item, index) in listleft1"
              :key="index"
              :src="item.image.publicUrl"
            />
          </div>
        </div>

        <div class="left-item">
          <div
            class="left-items"
            v-for="(item, index) in listleft2"
            :key="index"
          >
            <h3 class="left-items-title">{{ item.title }}</h3>
            <p
              class="left-items-info"
              v-html="item.content"
            ></p>

            <div class="left-items-img">
              <div class="left-items-imgs">
                <span class="nums">01</span>
                <p>
                  <span class="title">{{$t('phone')}}</span>
                  <span class="content">
                    <span v-if="phone">+86 </span>{{ item.phone }}
                  </span>
                </p>
              </div>
            </div>
            <div class="left-items-img">
              <div class="left-items-imgs">
                <span class="nums">02</span>
                <p>
                  <span class="title">{{$t('fax')}}</span>
                  <span class="content">{{ item.fax }}</span>
                </p>
              </div>
            </div>
            <div class="left-items-img">
              <div class="left-items-imgs">
                <span class="nums">03</span>
                <p>
                  <span class="title">{{$t('email')}}</span>
                  <span class="content">Joyceyy88@163.com</span>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="list-right">
        <div
          class="right-item"
          v-for="(item, index) in listright"
          :key="index"
          @click.prevent="rightItem(item.id)"
        >
          <span class="right-item-num">{{ (index + 1).toString().padStart(2, '0') }}</span>
          <h3 class="right-item-name">{{ item.title }}</h3>
          <div class="right-item-line">
            <img
              src="@/assets/image/home/listLine.png"
              alt=""
            />
          </div>
          <div class="right-item-info">
            <p class="right-item-title">{{ item.subtitle[0] }}</p>
            <p class="right-item-content">{{ item.subtitle[1] }}</p>
          </div>
          <div class="right-item-img">
            <img :src="item.image.publicUrl" />
          </div>
        </div>
      </div>
    </div>

    <div class="home-news">
      <div class="wrap">
        <h3 class="new-title"><img
            :src="$t('home.title')"
            alt=""
          /></h3>

        <div class="home-swiper">
          <swiper
            class="swiper"
            ref="swiper"
            :options="newSwiperOption"
          >
            <swiper-slide
              v-for="(item, index) in news"
              :key="index"
            >
              <div @click.prevent="toLink(item.id)" >

                <img
                  class="swiper-slide-img"
                  :src="item.image.publicUrl"
                  alt=""
                />
                <div class="home-swiper-list">
                  <p class="list-date">{{ item.createdAt | formatDate }}</p>
                  <p
                    class="list-info"
                    v-html="item.abstract "
                  >
                  </p>
                  <p class="list-link">
                    <span :class="item.id"> 查看详情 </span>
                  </p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-button-prev new-swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next new-swiper-button-next"
            slot="button-next"
          ></div>
          <div
            class="swiper-pagination new--swiper-pagination"
            slot="pagination"
          ></div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import HeadNav from './head-nav';
import Footers from './footers';

export default {
  name: 'home',
  title: 'Change direction',
  components: {
    HeadNav,
    Footers,
    Swiper,
    SwiperSlide,
  },
  apollo: {
    // 简单的查询，将更新 'hello' 这个 vue 属性
    banners: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              banners: allHomePageImages {
                id
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            banners: allEnHomePageImages {
              id
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
    listleft1: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              listleft1: allHomePageBusinessPhilosophies {
                id
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            listleft1: allEnHomePageBusinessPhilosophies {
              id
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
    listleft2: {
      query() {
        if (this.$i18n.locale === 'cn') {
          this.internationalPhone = this.$i18n.locale;
          return gql`
            query {
              listleft2: allHomePageInstroductions {
                id
                title
                content
                phone
                fax
              }
            }
          `;
        }
        this.internationalPhone = this.$i18n.locale;
        return gql`
          query {
            listleft2: allEnHomePageInstroductions {
              id
              title
              content
              phone
              fax
            }
          }
        `;
      },
    },

    listright: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              listright: allHomePageChessboards {
                id
                title
                subtitle
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            listright: allEnHomePageChessboards {
              title
              subtitle
              image {
                publicUrl
              }
            }
          }
        `;
      },
      update(value) {
        const newListright = value.listright.map((item) => ({
          ...item,
          subtitle: item.subtitle.split('\n'),
        }));
        return newListright;
      },
    },
    news: {
      query: gql`
        query {
          news: allPosts(orderBy: "createdAt_DESC", first: 9) {
            id
            title
            abstract
            createdAt
            image {
              publicUrl
            }
          }
        }
      `,
    },
  },
  watch: {
    internationalPhone() {
      if (this.internationalPhone === 'cn') {
        this.phone = false;
      } else if (this.internationalPhone === 'en') {
        this.phone = true;
      }
    },
  },
  data() {
    return {
      phone: false,
      internationalPhone: '',
      bannerSwiperOption: {
        centeredSlides: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        direction: 'horizontal',
        pagination: {
          el: '.banner--swiper-pagination',
          clickable: true,
          slidesOffsetBefore: 0,
        },
        navigation: {
          nextEl: '.banner-swiper-button-next',
          prevEl: '.banner-swiper-button-prev',
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? 'vertical' : 'horizontal',
            );
          },
        },
      },
      newSwiperOption: {
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        direction: 'horizontal',
        slidesPerView: 'auto',
        pagination: {
          el: '.new-swiper-pagination',
          clickable: true,
          slidesOffsetBefore: 0,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? 'vertical' : 'horizontal',
            );
          },
        },
      },
      banner: [],
      listleft1: [],
      listleft2: [],
      listright: [],
      news: [],
    };
  },
  methods: {
    toLink(id) {
      this.$router.push({
        name: 'about-news-details',
        params: {
          id,
        },
      });
    },
    rightItem(id) {
      this.$router.push({
        name: 'about-idea-details',
        params: {
          id,
        },
      });
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .banner-img {
    .swiper {
      img {
        width: 100%;
        height: auto;
      }

      ::v-deep .swiper-pagination {
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          border-radius: 0 !important;
          margin: 0 10px;
          outline: none;
          background: #fff;
          border: 1px solid #998d8d;
          opacity: 1;
        }

        .swiper-pagination-bullet-active {
          background: #ffbf00;
        }
      }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      color: #fec314;

      &::after {
        font-size: 44px;
      }
      outline: none !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      color: #fec314;
      border: none;
      cursor: pointer;

      &::after {
        font-size: 44px;
      }
      outline: none !important;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      color: #fff;
    }
  }

   .about-box {
    //  background: #eeeeee;
    .introduce {
      padding: 90px 0 80px 0;

      .introduce-title {
        text-align: center;
        margin-bottom: 30px;
      }

      .introduce-content {
        font-size: 14px;
        line-height: 20px;
        margin-top: 40px;
      }

      .introduce-img {
        margin-top: 30px;
        height: 450px;
        position: relative;

        .introduce-imgs {
          img {
            width: 100%;
            height: 100%;
          }
        }

        .introduce-imgs:nth-child(1) {
          width: 600px;
          height: 150px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .introduce-imgs:nth-child(2) {
          width: 300px;
          height: 300px;
          position: absolute;
          top: 0;
          left: 600px;
        }

        .introduce-imgs:nth-child(3) {
          width: 300px;
          height: 150px;
          position: absolute;
          top: 0;
          left: 900px;
        }

        .introduce-imgs:nth-child(4) {
          width: 300px;
          height: 300px;
          position: absolute;
          top: 150px;
          left: 0;
        }

        .introduce-imgs:nth-child(5) {
          width: 300px;
          height: 150px;
          position: absolute;
          top: 150px;
          left: 300px;
        }

        .introduce-imgs:nth-child(6) {
          width: 150px;
          height: 150px;
          position: absolute;
          top: 300px;
          left: 300px;
        }

        .introduce-imgs:nth-child(7) {
          position: absolute;
          left: 450px;
          top: 300px;
          width: 450px;
          height: 150px;
        }

        .introduce-imgs:nth-child(8) {
          position: absolute;
          left: 900px;
          top: 150px;
          width: 300px;
          height: 300px;
        }
      }
    }
  }

  .home-list {
    padding-bottom: 100px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .list-left {
      width: 390px;

      .left-item {
        margin-bottom: 20px;
        height: 350px;
        border: 1px solid #eee;
        border-radius: 10px;
        .left-items {
          margin: 0 auto;
          width: 280px;
        }

        .left-items-title {
          font-size: 24px;
          text-align: center;
          margin: 24px 0 10px 0;
        }

        .left-items-info {
          color: #818181;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 16px;
          height: 80px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
        .left-items-img {
          margin: 20px 0;
          .left-items-imgs {
            height: 36px;
            position: relative;

            .nums {
              display: inline-block;
              width: 45px;
              height: 22px;
              line-height: 24px;
              text-align: center;
              color: #ffff;
              background: #ffbf00;
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
              position: absolute;
              top: 13px;
              left: -11px;
              transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              -moz-transform: rotate(270deg);
            }

            p {
              line-height: 45px;
              padding-left: 44px;
              border: 1px solid #eee;
              border-radius: 6px;

              .title {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }

    .list-right {
      width: 810px;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      justify-content: space-between;
      cursor: pointer;

      .right-item {
        width: 248px;
        height: 350px;
        margin: 0 0 20px 20px;
        text-align: center;
        position: relative;
        border: 1px solid #eee;
        border-radius: 10px;

        .right-item-num {
          position: absolute;
          top: 15px;
          left: 15px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          background: #ffbf00;
          border-radius: 50%;
          font-size: 0.6rem;
          display: inline-block;
        }

        .right-item-name {
          font-size: 24px;
          font-weight: 500;
          margin-top: 50px;
        }

        .right-item-line {
          margin: 18px auto;
        }

        .right-item-info {
          height: 40px;
          line-height: 20px;
          .right-item-title,
          .right-item-content {
            color: #818181;
          }
        }
        .right-item-img {
          margin-top: 30px;
        }
      }
      .right-item:nth-child(2n + 2) {
        background: #666666;

        .right-item-name,
        .right-item-title,
        .right-item-content {
          color: #fff;
        }
      }
    }
  }

  /* 新闻 */

  .home-news {
    background: #eeeeee;
    height: 650px;

    .wrap {
      padding-top: 100px;

      .new-title {
        text-align: center;
      }

      .home-swiper {
        margin-top: 46px;
        position: relative;

        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
          left: -44px;
          color: #fec314;

          &::after {
            font-size: 40px;
          }
          outline: none !important;
        }

        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
          right: -44px;
          color: #fec314;
          border: none;
          cursor: pointer;

          &::after {
            font-size: 40px;
          }
          outline: none !important;
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          color: #818181;
        }

        ::v-deep .swiper-pagination {
          left: 44%;
          top: 360px;

          .swiper-pagination-bullet {
            border-radius: 0 !important;
            margin: 0 6px;
            outline: none;
          }

          .swiper-pagination-bullet-active {
            background: #ffbf00;
          }
        }

        .swiper-slide {
          width: 240px !important;
          height: auto !important;
          overflow: hidden;

          &:hover {
            cursor: pointer;
            .home-swiper-list {
              background: #ffbf00;

              &::after {
                border-color: transparent transparent #ffbf00;
              }
              .list-date {
                color: #333;
              }

              .list-link {
                span {
                  color: #000;
                }
              }
            }

            .swiper-slide-img {
              transform: scale(1.2);
            }
          }

          .swiper-slide-img {
            height: 160px;
            width: 100%;
            max-width: 100%;
            transition: all 1000ms;
          }

          .home-swiper-list {
            height: 160px;
            background: #fff;
            position: relative;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-width: 0 14px 10px;
              border-style: solid;
              border-color: transparent transparent #fff;
              position: absolute;
              top: -10px;
              left: 45%;
            }
          }

          p {
            margin: 0 auto;
            width: 190px;
            font-size: 14px;
          }

          .list-date {
            padding-top: 24px;
            font-size: 12px;
            color: #a8a8a8;
          }

          .list-info {
            padding: 14px 0 18px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            height: 70px;
            line-height: 18px;
            font-size: 14px;
          }

          .list-link {
            text-align: right;
            margin-top: 20px;

            span {
              color: #999999;
            }
          }
        }
        .swiper-slide:nth-child(2n + 2) {
          &:hover {
            .home-swiper-list::after {
              border-color: #ffbf00 transparent transparent;
            }
          }
          position: relative;

          .swiper-slide-img {
            position: relative;
            top: 160px;
            left: 0;
            z-index: 1;
          }

          .home-swiper-list {
            position: relative;
            top: -160px;
            z-index: 1;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-width: 12px 14px 0;
              border-style: solid;
              border-color: #fff transparent transparent;
              position: relative;
              top: 35px !important;
              left: 44%;
              z-index: 99;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .banner-img .swiper-wrapper .swiper-slide,
  .home .home-news {
    width: 1200px;
  }

  .home .home-news .wrap .home-swiper .swiper-container .swiper-wrapper {
    width: 1200px !important;
    display: flex !important;
    flex: 1 !important;
    justify-content: space-around !important;
  }

  .home .home-news .wrap .home-swiper .swiper-slide {
    height: 100% !important;
  }

  .home .home-news .wrap .home-swiper .swiper-button-next,
  .home .home-news .wrap .home-swiper .swiper-container-rtl .swiper-button-prev {
    display: none !important;
  }
}
</style>
